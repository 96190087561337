/* :root {
    
    --bg-light: #e5ebe7;
    --bg-info: #A3D9EA;
    --bg-success: #A3EAD4;
    --bd-light: white;
    --accent-color: #CEAFE7;
    --text-light: rgba(46, 34, 34, 0.25);
    --text-dark-bold: rgba(14, 13, 13, 0.925);
    --text-dark: #282d31;
    --text-lighter: #c4c4c4;
    --text-dimmed: #9D94A3;
    --shadow-color-dark: rgba(0, 0, 0, .25);
    --shadow-color-light:  white; 
  

    --bg-light: #1B2D38;
    --bg-info: #A3D9EA;
    --bg-success: #A3EAD4;
    --bd-light: rgb(17, 17, 17);
    --accent-color: #aaca35;
    --text-light: rgba(255, 248, 248, 0.644);
    --text-dark-bold: rgba(255, 255, 255, 0.925);
    --text-dark: #ffffff;
    --text-lighter: #fcf8f8;
    --text-dimmed: #ffffff;
    --shadow-color-dark: rgba(0, 0, 0, .25);
    --shadow-color-light:  rgba(255, 255, 255, 0.1);
  } */
 

  
  
  #root{
    height: 100vh;
    width: 100vw;
  }
  .loading-cont{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
  @keyframes fading{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  
  @keyframes spin{
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }
  .gw-accent-color{
    color: var(--accent-color) !important;
  }
  
  .loading{
    animation: fading 1s infinite alternate-reverse;
  }
  
  .spinning{
    animation: spin 1500ms infinite linear;
  }
  
  .gw-success-color {
    color: #A3EAD4;
  }
  
  .gw-danger-color{
    color: #F8BDBD !important;
  }
  
  .gw-info-color{
    color: #A3D9EA !important;
  }
  body {
    width: 100vw;
    height: 100vh;
    background-color: var(--bg-light);
    color: var(--text-dark)
  }
  
  .heading-1, .heading-3, .heading-4, .heading-5, .heading-6, .brand-name {
    /* font-family: "Lora"; */
    font-family: "Archivo", sans-serif;
  }
  
  .heading-1 {
    font-size: 3.8rem;
    font-weight: 700;
    line-height: 1em;
  }
  
  .inner-shadow {
    box-shadow: inset 3px -3px 12px var(--shadow-color-dark), inset -10px 10px 20px var(--shadow-color-light);
  }
  
  .btn-gw-primary {
    background-color: var(--accent-color);
    color: var(--text-dark);
    border-radius: 12px;
  }
  .btn-gw-primary-outline {
    background-color: var(--bg-light);
    color: var(--accent-color);
    border-radius: 12px;
    border: 2px solid var(--accent-color);
  }
  
  .btn-gw-secondary {
    color: var(--accent-color);
    background-color: var(--bg-light);
    border-radius: 12px;
  }
  .btn-gw-info {
    color: var(--text-dark);
    background-color: var(--bg-info);
    border-radius: 12px;
  }
  .btn-gw-success {
    color: var(--text-dark);
    background-color: var(--bg-success);
    border-radius: 12px;
  }
  
  .btn-gw-circle {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    padding: 10px;
  }
  
  .brand-name {
    font-weight: 700;
    font-size: 2rem;
    color: var(--accent-color);
    text-shadow: 3px 3px 3px var(--shadow-color-dark), -2px -2px 5px var(--shadow-color-light);
  }
  
  .inner-shadow-sm {
    box-shadow: inset 3px 3px 2px var(--shadow-color-dark), inset -3px -3px 5px var(--shadow-color-light);
  }
  
  .inner-shadow-xs {
    box-shadow: inset 2px 2px 2px var(--shadow-color-dark), inset -2px -2px 2px var(--shadow-color-light);
  }
  
  .outer-shadow-sm {
    box-shadow: 2px 2px 3px var(--shadow-color-dark), -2px -2px 3px var(--shadow-color-light);
  }
  .outer-shadow-lg {
    box-shadow: 2px 2px 10px var(--shadow-color-dark), -5px -5px 5px var(--shadow-color-light);
  }
  
  
  .gw-label{
    font-size: 10px;
    color: var(--text-light);
    display: inline-block;
    margin-bottom: 30px
  }
  
  .gw-select{
    background: var(--bg-light);
    font-size: 14px;
    border: none;
    outline: none;
    border-radius: 15px;
    margin-top: 10px;
  }

  .theme-circle{
    width:3em;
    height: 3em;
    border-radius: 50%;
  }

  .theme-container{
    margin-left: 1em;
  }
  .theme-container.selected .theme-circle{
    border: 5px solid var(--accent-color);

  }

  .theme-circle.default{
    background: #e5ebe7;
  }
  .theme-circle.dark{
    background: #121413;
  }
  .theme-circle.royal-dark{
    background: #6d4463;
  }
  .theme-circle.dark-lemon{
    background: #aaca35;
  }
  
  