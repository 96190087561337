.avatar-container{
    width: 82px;
    height: 82px;
    border-radius: 41px;
    display: grid;
    place-items: center;
}
.profile-avatar{
    width : 80px;
    height: 80px;
    border-radius: 40px;
}

input.file-input{
    display: none;
}

.file-input-label{
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0 20px;
    border-radius: 20px;
}


@media screen and (max-width:600px){
    .avatar-container{
        justify-self: flex-start;
    }
}