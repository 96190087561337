.inner-shadow {
  box-shadow: inset 3px 3px 6px var(--shadow-color-dark), inset -5px -5px 6px var(--shadow-color-light);
}

.inner-shadow-small {
  box-shadow: inset 2px 2px 4px var(--shadow-color-dark), inset -2px -2px 3px var(--shadow-color-light);
}

.inner-outer-shadow {
  box-shadow: 3px 3px 5px var(--shadow-color-dark), -3px -3px 5px var(--shadow-color-light), inset 2px 2px 10px var(--shadow-color-dark), inset -4px -4px 10px var(--shadow-color-light);
}

.outer-shadow {
  box-shadow: 3px 3px 5px var(--shadow-color-dark), -3px -3px 5px var(--shadow-color-light);
}

input, input.form-control, .form-control {
  background: var(--bg-light);
  color: var(--text-light);
}

.form-control:read-only, .form-control:focus {
  background: var(--bg-light);
  color: var(--text-light);
}

.db-left-col {
  border-radius: 31px;
  position: fixed;
  height: max-content;
  width: 20%;
  left: 5em;
  top: 3em;
  bottom: 3em;
}

.logout-icon {
  width: 45px;
  height: 45px;
  border: 1px solid #e5ebe7;
  padding: .9em;
  color: var(--accent-color) !important;
}

.sm-header {
  border-radius: 24px;
}

.overview .column {
  border-radius: 10px;
  width: 30%;
}

.mobile-footer {
  position: fixed;
  left: 5px;
  right: 5px;
  border-radius: 15px;
  bottom: 0;
  background-color: var(--bg-light);
  box-shadow: 3px 3px 5px var(--shadow-color-dark), -3px -3px 5px var(--shadow-color-light);
}

.mobile-footer a {
  display: grid;
  place-content: center;
  font-size: 1.5rem;
  color: var(--accent-color);
  padding: 15px;
  border-radius: 7px;
  width: 60px;
  height: 60px;
  text-align: center;
}

.mobile-footer .active, .nav-active {
  box-shadow: inset 3px 3px 5px var(--shadow-color-dark), inset -5px -5px 6px var(--shadow-color-light);
}

.inner-shadow i {
  color: var(--accent-color);
}

.inner-shadow div h4 {
  font-weight: 600;
  /* font-family: "Lora"; */
}

.inner-shadow div p {
  font-size: 12px;
  /* font-family: "Roboto"; */
  color: var(--text-lighter);
}

.recent-act {
  border-radius: 10px;
}

.heading-5 {
  color: var(text-dark-bold);
  font-size: 16px;
  font-weight: 700;
}

.btn-gw-circle {
  color: var(--accent-color);
}

/* .right-col {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
} */

.right-col::-webkit-scrollbar {
  width: 0;
  height: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.activity>div {
  transition: box-shadow 1200ms linear, border-radius 900ms ease-in-out;
}

.action-btn {
  position: relative;
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: grid;
  place-content: center;
}

.action-btn .badge {
  position: absolute;
  top: 5px;
  left: 20px;
  background: var(--bg-light);
  color: red !important;
  border-radius: 50%;
  font-size: 10px;
}

.del-icon {
  color: #F8BDBD;
}

.success-icon {
  color: #A3EAD4;
}

.print-icon, .blue-clr {
  color: #A3D9EA;
}

.activity span, .date {
  font-size: 12px;
}

.det-container {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.date {
  color: var(--accent-color);
  padding-left: 5px;
  font-weight: bold;
}

.desc {
  font-size: 14px;
  line-height: 1.8;
  padding-left: 5px
}

.check-name {
  /* font-family: "Lora"; */
  font-weight: 700;
  line-height: 1rem;
}

.nav a {
  margin-bottom: 2rem;
  width: 90%;
  padding: 1rem 0;
  padding-left: 2rem;
  font-size: 20px;
  border-radius: 10px;
  color: var(--text-lighter);
  transition: box-shadow 1200ms ease-in;
}

.nav-active {
  color: var(--accent-color);
}

.placeholder-sm {
  display: inline-block;
  width: 35px;
  height: 10px;
  background: var(--accent-color);
}

.placeholder-lg {
  display: inline-block;
  width: 100%;
  height: 35px;
  background: var(--text-dark-bold);
}

.placeholder-exp {
  width: 100%;
  height: 45px;
  background: var(--text-dimmed);
}

.modal-container {
  position: absolute;
  display: none;
  width: 100%;
  flex-direction: column;
  justify-self: center;
  top: 0;
  z-index: 5;
  background: var(--bg-light);
}

.modal-container.open {
  display: flex;
}

.gw-modal {
  position: relative;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  outline: none;
  border: none;
  color: var(--bg-info);
}

@media (max-width: 600px) {
  body {
    margin: 0;
    width: 100vw;
    overflow-x: hidden;
    box-sizing: border-box;
  }
  .action-btn {
    margin-top: 1em;
    width: 50px;
    height: 50px;
  }
  .right-col {
    margin: 0 !important;
    padding: 0 .5em !important;
  }
  .date, .desc {
    padding: 0.1px 0;
  }
  .desc {
    line-height: 1.9;
    font-size: 16px;
  }
  .det-container {
    margin: 1rem 0;
    padding: 0 0 !important;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
  }
  .action-container {
    padding: 0 1em;
    display: flex;
    flex-direction: row;
  }
  .action-container button:not(:first-child) {
    margin-left: 50px;
  }
  .action-container a:not(:first-child) {
    margin-left: 50px;
  }
  .recent-act {
    box-shadow: 3px 3px 5px var(--shadow-color-dark), -3px -3px 5px var(--shadow-color-light);
  }

 .form-control.inner-shadow-sm{
   border: 1px solid var(--text-dark);
 }
 input[type=text], select{
   border: 1px solid var(--text-dark);
 }
}

@media (min-width: 1000px) {
  .activity>div:hover {
    box-shadow: inset 3px 3px 5px var(--shadow-color-dark), inset -5px -5px 6px var(--shadow-color-light);
    border-radius: 10px;
  }
}