body {
    margin: 0;
    font-family: "Archivo", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.info-modal * {
    background-color: #006c37 !important;
    color: #fff !important;
}

.info-modal #btn {
    background-color: #f7f7f7 !important;
    color: #006c37 !important;
    font-weight: bold;
}

.info-modal #close-btn {
    background-color: #1a1a1a !important;
    color: #f7f7f7 !important;
    font-weight: bold;
}

.info-modal #note {
    color: #ffd700 !important;
    font-weight: bold;
}
